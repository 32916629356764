import moment from 'moment';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import BaseModel from './BaseModel';
import DOB from './DOB';

class Recipient extends BaseModel {
  constructor(doc) {
    super(doc);
    this.phones = this.phones || [];
    this.emails = this.emails || [];
    this.signedNotes = this.signedNotes || [];
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getTruncatedId() {
    const parts = this._id.split('-');

    return parts.length > 2 && parts[2];
  }

  getIdentifier() {
    const identifier = find(this.identifiers, {
      namespace: 'default/identifier',
    });
    return identifier && identifier.value;
  }

  getNationalId() {
    const identifier = find(this.identifiers, {
      namespace: 'default/national_health_identifier',
    });
    return identifier && identifier.value;
  }

  getPrimaryIdentifier() {
    if (this.identifiers && this.identifiers.length > 0) {
      return this.identifiers[0].value;
    }
    return undefined;
  }

  getPatientServiceId() {
    return this.patientServiceId || this._id;
  }

  getEmailAddress() {
    return this.emails && this.emails[0] && this.emails[0].address;
  }

  getEmailAddresses() {
    return this.emails || [];
  }

  hasEmailAddresses() {
    return !!this.emails && this.emails.length > 0;
  }

  getPhoneNumber() {
    return this.phones && this.phones[0] && this.phones[0].number;
  }

  getPhoneNumbers() {
    return this.phones || [];
  }

  hasPhoneNumbers() {
    return !!this.phones && this.phones.length > 0;
  }

  getLanguagePreference() {
    return this.languagePreference || [];
  }

  getUpdatedEmails(newEmails) {
    const emails = keyBy(this.emails, 'address');
    return map(newEmails, ({ address, type }) => {
      // NOTE: This allows to keep some existing properties unchanged,
      //       e.g. "verified" flag.
      return {
        ...emails[address],
        address,
        type,
      };
    });
  }

  getUpdatedPhones(newPhones) {
    const phones = keyBy(this.phones, 'number');
    return map(newPhones, ({ number, type }) => {
      // NOTE: This allows to keep some existing properties unchanged,
      //       e.g. "verified" flag.
      return {
        ...phones[number],
        number,
        type,
      };
    });
  }

  hasStructuredName() {
    if (this.name) {
      return !isEmpty(this.name.given) || this.name.family;
    }
    return false;
  }

  getFirstName() {
    if (this.name && this.name.given && this.name.given[0]) {
      return this.name.given[0];
    }
    return undefined;
  }

  getLastName() {
    if (this.name && this.name.family) {
      return this.name.family;
    }
    return undefined;
  }

  getFullName() {
    if (!this.name) {
      return '';
    }
    if (this.name.text) {
      return this.name.text;
    }
    const chunks = this.name.given ? [...this.name.given] : [];
    if (this.name.family) {
      chunks.push(this.name.family);
    }
    return chunks.join(' ');
  }

  getGender() {
    return this.gender || '';
  }

  getAge() {
    if (this.birthDate) {
      return DOB.fromString(this.birthDate).getCurrentAge();
    }
    return NaN;
  }

  formatEthnicity() {
    if (this.ethnicityOtherSpecified) {
      return `Other / ${this.ethnicityOtherSpecified}`;
    }
    return this.ethnicity;
  }

  getYearOfBirth() {
    return DOB.fromString(this.birthDate).getYear();
  }

  formatDateOfBirth(format) {
    if (!this.birthDate) {
      return '';
    }
    const dob = DOB.fromString(this.birthDate);
    if (dob.isYearOnly()) {
      return dob.getYear();
    }
    return moment(dob.toString(), moment.ISO_8601).format(
      format || 'MMMM Do YYYY',
    );
  }

  formatCreatedAt() {
    return !this.createdAt ? '' : moment(this.createdAt).format('MMMM Do YYYY');
  }

  /**
   * Get first preferred language that is available.
   * @param {String[]} availableLanguages
   */
  getPreferredLanguage(availableLanguages) {
    const isAvailable = {};
    forEach(availableLanguages, (l) => {
      isAvailable[l] = true;
    });
    if (!availableLanguages) {
      return this.languagePreference && this.languagePreference[0];
    }
    return find(this.languagePreference, (language) => isAvailable[language]);
  }
  getUnsubscribed() {
    return this.unsubscribed || [];
  }
  getPreferredNotificationMethod() {
    return this.preferredNotificationMethod || '';
  }
}

Recipient.collection = 'Recipients';
Recipient.scopeName = '@recipient';
Recipient.piiFields = ['name', 'identifiers', 'emails', 'phones', 'birthDate'];

export default Recipient;
