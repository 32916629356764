import styled, { css } from 'styled-components';
import {
  SettingOutlined,
  EyeOutlined,
  ContainerOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import { MoreVert } from 'styled-icons/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Button from '../../../../components/Button';
import Menu from '../../../../common/components/Menu';
import Dropdown from '../../../../common/components/Dropdown';
import Stack from '../../../../common/components/primitives/Stack';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Text from '../../../../common/components/base/Text';
import { openTab } from '../../../ProjectsDashboard/actions';
import {
  PARTICIPATION_STATE__ENDED,
  PARTICIPATION_STATE__CANCELED,
  PARTICIPATION_STATE__TERMINATED,
} from '../../../../common/constants';
import {
  PATIENT_VIEW_PATIENT_RESULTS,
  PROJECT_PROFILE_DISCHARGE_PATIENT,
} from '../../../../common/permissions';
import usePermission from '../../../../utils/usePermission';
import { default as PatientRecordSelect } from '../../../../common/selectors/PatientRecord';
import { default as ProjectMilestoneSelect } from '../../../../common/selectors/ProjectMilestone';
import { openProjectProfileDialog } from '../../store';
import { theme } from '../../../../common/utilsClient/cssHelpers';
import branding from '../../../../utils/branding';
import DischargeRecipient from '../../../../components/dialogs/DischargeRecipient';

const backgroundColorCSS = css`
  &::after {
    background-color: ${theme('color.overlay')};
  }
`;

const StyledContainer = styled.div`
  ${(props) =>
    props.isDisabled &&
    `
      position: relative;
      pointer-events: none;
      user-select: none;
      overflow: hidden;

      &::after {
        content: '';
        background-color: hsla(210, 28%, 100%, 0.75);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
  `}

  ${(props) => props.isDisabled && backgroundColorCSS}
`;

const ProjectProfilePresentation =
  // ...
  ({
    recipientId,
    participationId,
    participationState,
    projectId,
    projectName,
    unsubscribed,
    patientPreferredMethod,
  }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    const [
      isDischargeRecipientModalVisible,
      setIsDischargeRecipientModalVisible,
    ] = useState(false);

    const handleOnOpenDischargeRecipientModal = () =>
      setIsDischargeRecipientModalVisible(true);
    const handleOnCloseDischargeRecipientModal = () =>
      setIsDischargeRecipientModalVisible(false);

    const patientRecord = useSelector(
      PatientRecordSelect.one().where({
        participationId,
      }),
    );

    const firstActivity =
      patientRecord && patientRecord.getFirstRelevantActivity();

    let firstMilestoneId;
    let firstMilestoneJoinedOn;

    if (firstActivity) {
      firstMilestoneId = firstActivity.milestoneId;
      if (firstActivity.dateStart) {
        firstMilestoneJoinedOn = toIntlDateTimeFormat(
          language,
          firstActivity.dateStart,
          { dateStyle: 'short' },
        );
      }
    }

    const firstMilestone = useSelector(
      ProjectMilestoneSelect.one().whereIdEquals(firstMilestoneId),
    );

    let firstMilestoneName;
    if (firstMilestone) {
      firstMilestoneName = firstMilestone.name;
      if (!firstMilestoneJoinedOn) {
        const { dateStart } = firstMilestone.getBaselineSchedule(
          patientRecord.baseline,
        );
        firstMilestoneJoinedOn = toIntlDateTimeFormat(language, dateStart, {
          dateStyle: 'short',
        });
      }
    }

    const dispatch = useDispatch();
    const onInsightsClick = useCallback(() => {
      // NOTE: We are not changing query here, so it will lead us to patient charts most likely.
      dispatch(
        openTab({
          projectId,
          settings: {
            patientId: recipientId,
          },
        }),
      );
    }, [dispatch, projectId, recipientId]);
    const isDischarged = participationState === PARTICIPATION_STATE__ENDED;
    const canViewPatientResults = usePermission([PATIENT_VIEW_PATIENT_RESULTS]);
    const canDischarge = usePermission([PROJECT_PROFILE_DISCHARGE_PATIENT]);

    const history = useHistory();
    const onResponsesClick = () =>
      history.push(`/patients/${recipientId}/projects/${projectId}`);
    const onSettingsClick = useCallback(() => {
      dispatch(
        openProjectProfileDialog({
          projectId,
          recipientId,
          participationId,
        }),
      );
    }, [dispatch, projectId, recipientId, participationId]);
    const onDischargePatientClick = () => {
      if (!canDischarge || isDischarged) {
        return;
      }

      handleOnOpenDischargeRecipientModal();
    };

    return (
      <>
        <Sidebar
          sidebar={
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    data-testid="menu-item-settings"
                    icon={<SettingOutlined />}
                    onClick={onSettingsClick}
                  >
                    {t('settings')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<EyeOutlined />}
                    onClick={onInsightsClick}
                    disabled={
                      participationState === PARTICIPATION_STATE__TERMINATED ||
                      participationState === PARTICIPATION_STATE__CANCELED
                    }
                  >
                    {t('insights')}
                  </Menu.Item>
                  <Menu.Item
                    icon={<ContainerOutlined />}
                    onClick={onResponsesClick}
                  >
                    {t('responses')}
                  </Menu.Item>
                  {canDischarge && (
                    <>
                      <Menu.Divider />
                      <Menu.Item
                        icon={<UserDeleteOutlined />}
                        onClick={onDischargePatientClick}
                        disabled={isDischarged}
                      >
                        {t('dischargeRecipients', {
                          count: 1,
                          context: branding,
                        })}
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                data-testid="button-project-menu"
                type="tertiary"
                icon={<MoreVert />}
              />
            </Dropdown>
          }
        >
          <StyledContainer isDisabled={isDischarged}>
            <Stack space={0}>
              {canViewPatientResults ? (
                <Text.Link
                  data-testid={`button-to-${projectName}`}
                  to={`/projects/${projectId}`}
                  size="large"
                  importance="high"
                  className="interactive-over-overlay"
                >
                  {projectName}
                </Text.Link>
              ) : (
                <Text.Span size="large" importance="high">
                  {projectName}
                </Text.Span>
              )}
              <Text.Paragraph>
                {participationState &&
                  `${t('forms:state.label')}: ${t(
                    `stateMachines:Participation.states.${participationState.toLowerCase()}`,
                  )}`}
                {firstMilestoneJoinedOn && (
                  <>
                    {'; '}
                    <Text.Span importance="low">
                      {t('projects:joined.on')}
                    </Text.Span>
                    <Text.Span importance="high">
                      {' '}
                      {firstMilestoneJoinedOn}{' '}
                    </Text.Span>
                  </>
                )}
                {firstMilestoneJoinedOn && firstMilestoneName && (
                  <>
                    <Text.Span importance="low">
                      {t('projects:joined.to')}
                    </Text.Span>
                    <Text.Span importance="high">
                      {' '}
                      {`'${firstMilestoneName}'`}
                    </Text.Span>
                  </>
                )}
                {unsubscribed && (
                  <div className="text-left text-yellow-500">
                    {t('unsubscribed patient')}
                  </div>
                )}
                {!unsubscribed && patientPreferredMethod && (
                  <div className="text-left text-yellow-500">
                    {patientPreferredMethod === 'email'
                      ? t('patient preferred method email')
                      : patientPreferredMethod === 'sms'
                      ? t('patient preferred method sms')
                      : t('patient preferred method both')}
                  </div>
                )}
              </Text.Paragraph>
            </Stack>
          </StyledContainer>
        </Sidebar>
        <DischargeRecipient
          recipientId={recipientId}
          participationId={participationId}
          visible={isDischargeRecipientModalVisible}
          onCancel={handleOnCloseDischargeRecipientModal}
        />
      </>
    );
  };

ProjectProfilePresentation.propTypes = {
  recipientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  participationState: PropTypes.string.isRequired,
  unsubscribed: PropTypes.bool.isRequired,
};

ProjectProfilePresentation.defaultProps = {
  projectName: null,
};

export default ProjectProfilePresentation;
