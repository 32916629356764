import {
  EAPP_PRIORITY_LOW,
  EAPP_PRIORITY_MEDIUM,
  EAPP_PRIORITY_HIGH,
} from '../../../../../common/schemata/ProjectEAPPRule';

export const getPriorityType = (priority) => {
  switch (priority) {
    case EAPP_PRIORITY_HIGH:
      return 'danger';
    case EAPP_PRIORITY_MEDIUM:
      return 'warning';
    case EAPP_PRIORITY_LOW:
      return 'success';
    default:
      return null;
  }
};
