import isEqual from 'lodash/isEqual';
import React from 'react';
import PropTypes from 'prop-types';
import renderMarks from './renderMarks';

/**
 * @typedef {object} HighlighterProps
 * @property {string} [className]
 * @property {string} [text]
 * @property {string | string[]} [highlight]
 * @property {string} [type]
 */

/** @type {React.FC<HighlighterProps>} */
const Highlight = ({ className, text, highlight, type }) => {
  return (
    <span className={className}>{renderMarks(text, highlight, type)}</span>
  );
};

Highlight.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  highlight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string.isRequired),
  ]),
  type: PropTypes.string,
};

Highlight.defaultProps = {
  className: '',
  text: '',
  highlight: [],
  type: '',
};

export default React.memo(Highlight, isEqual);
