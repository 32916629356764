import { QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES } from '../constants';
import Id from './Id';

export const EAPP_TRIGGER_TYPE__QUESTIONNAIRE_COMPLETION =
  'questionnaireCompletion';
export const EAPP_TRIGGER_TYPE__ACTIVITY_COMPLETION = 'activityCompletion';
export const EAPP_TRIGGER_TYPE__ACTIVITY_STATE_TRANSITION =
  'activityStateTransition';
export const EAPP_TRIGGER_TYPES = [
  EAPP_TRIGGER_TYPE__QUESTIONNAIRE_COMPLETION,
  EAPP_TRIGGER_TYPE__ACTIVITY_COMPLETION,
  EAPP_TRIGGER_TYPE__ACTIVITY_STATE_TRANSITION,
];

export const EAPP_ACTION_TYPE__SEND_NOTIFICATION = 'sendNotification';
export const EAPP_ACTION_TYPE__CREATE_ACTIVITY = 'createActivity';
export const EAPP_ACTION_TYPE__AWAIT_MANUAL_INTERVENTION =
  'awaitManualIntervention';
export const EAPP_ACTION_TYPE__CHANGE_TRACK = 'changeTrack';

export const EAPP_ACTION_TYPES = [
  EAPP_ACTION_TYPE__SEND_NOTIFICATION,
  EAPP_ACTION_TYPE__CREATE_ACTIVITY,
  EAPP_ACTION_TYPE__AWAIT_MANUAL_INTERVENTION,
  EAPP_ACTION_TYPE__CHANGE_TRACK,
];

export const EAPP_PRIORITY_LOW = 'low';
export const EAPP_PRIORITY_MEDIUM = 'medium';
export const EAPP_PRIORITY_HIGH = 'high';
export const EAPP_PRIORITIES = [
  EAPP_PRIORITY_LOW,
  EAPP_PRIORITY_MEDIUM,
  EAPP_PRIORITY_HIGH,
];

export const EAPP_ACTIVITY_STATE_TRANSITION__COMPLETE = 'complete';
export const EAPP_ACTIVITY_STATE_TRANSITION__START = 'start';
export const EAPP_ACTIVITY_STATE_TRANSITION__EXPIRE = 'expire';
export const EAPP_ACTIVITY_STATE_TRANSITIONS = [
  EAPP_ACTIVITY_STATE_TRANSITION__COMPLETE,
  EAPP_ACTIVITY_STATE_TRANSITION__START,
  EAPP_ACTIVITY_STATE_TRANSITION__EXPIRE,
];

const ProjectEAPPRule = {
  type: 'object',
  required: ['projectId'],
  properties: {
    projectId: Id,
    index: {
      type: 'number',
    },
    archived: {
      type: 'boolean',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    priority: {
      type: 'string',
      enum: EAPP_PRIORITIES,
    },
    triggerOnActivityStateTransition: {
      type: 'string',
    },
    triggerType: {
      type: 'string',
      enum: EAPP_TRIGGER_TYPES,
    },
    assigneeTypes: {
      type: 'array',
      items: {
        type: 'string',
        enum: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
      },
    },
    selectedMilestonesOnly: {
      type: 'boolean',
    },
    selectedMilestones: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    selectedQuestionnairesOnly: {
      type: 'boolean',
    },
    selectedQuestionnaires: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    keywordMatchRulesEnabled: {
      type: 'boolean',
    },
    keywordMatchRulesQuestionId: {
      type: 'string',
    },
    keywordMatchRulesKeywords: {
      type: 'string',
    },
    variables: {
      // source code to evaluate context variables
      type: 'string',
    },
    condition: {
      // source code to evaluate filtering condition
      type: 'string',
    },
    actionsToTrigger: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'type'],
        properties: {
          id: {
            type: 'string',
          },
          type: {
            type: 'string',
            enum: EAPP_ACTION_TYPES,
          },
          useVariables: {
            type: 'boolean',
          },
          variables: {
            type: 'string',
          },
          milestoneId: {
            type: 'string',
          },
          notificationId: {
            type: 'string',
          },
          trackId: {
            type: 'string',
          },
        },
      },
    },
  },
};

export default ProjectEAPPRule;
