import styled, { css } from 'styled-components';
import {
  FileOutlined,
  ClockCircleOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined,
  LockOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
import { getIdentifier } from '@zedoc/questionnaire';
import AnswersSheet from '../../../../common/models/AnswersSheet';
import FormStart from './FormStart';
import FormActions from './FormActions';
import Box from '../../../../common/components/primitives/Box';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Stack from '../../../../common/components/primitives/Stack';
import Text from '../../../../common/components/base/Text';
import Skeleton from '../../../../common/components/Skeleton';
import Button from '../../../../components/Button';
import LocalDate from '../../../../components/plates/LocalDate';
import { isInProgress } from '../../helpers';
import { theme } from '../../../../common/utilsClient/cssHelpers';

const getBorder = (type) => css`
  border: 1px solid ${theme(`color.border`)};
  border-left: 4px solid ${theme(`color.${type}`)};
`;

const StyledBox = styled(Box.Primary)`
  ${(props) => getBorder(props.type)};
`;

const FormsListItem = compose(
  withHandlers({
    // TODO: Refactor `onPreview`
    onPreview:
      ({ onPreview, answersSheetId }) =>
      () =>
        onPreview(answersSheetId),
    handleDashboardsPerspectiveResponsesPreview:
      ({ onDashboardsPerspectiveResponsesPreview, answersSheet }) =>
      ({ type, presetNo }) => {
        if (onDashboardsPerspectiveResponsesPreview && answersSheet) {
          onDashboardsPerspectiveResponsesPreview({
            type,
            presetNo,
            answersSheet,
          });
        }
      },
  }),
)(
  ({
    answersSheet,
    onPreview,
    disabled,
    userNames,
    questionnaireNames,
    due,
    pastDue,
    activityDateEnd,
    dashboardsPerspectiveResponses,
    handleDashboardsPerspectiveResponsesPreview,
  }) => {
    const { t } = useTranslation();

    const paused = answersSheet.isStartedAndPaused();
    const completed = answersSheet.isCompleted();
    const suspended = answersSheet.isSuspended();
    const canceled = answersSheet.isCanceled();
    const inProgress = isInProgress(answersSheet);
    // For now it's truthy when AS has any notes
    // const hasUnreadNotes = answersSheet.signedNotes.length > 0;
    const dimmed = canceled || suspended;
    const questionnaireId = answersSheet.getQuestionnaireId();
    const identifier = getIdentifier(questionnaireId);
    const questionnaireName = questionnaireNames[identifier] || `${identifier}`;
    // const clickDisabled = disabled || canceled;

    let title;
    let icon = <FileOutlined />;
    let type;

    if (pastDue) {
      type = 'danger';
      title = t('projects:past');
      icon = <ClockCircleOutlined className="align-middle" />;
    }
    if (due) {
      type = 'warning';
      title = t('projects:due');
      icon = <ClockCircleOutlined className="align-middle" />;
    }
    if (paused) {
      title = t('projects:paused');
      icon = <PauseCircleOutlined className="align-middle" />;
    }
    if (completed) {
      type = 'success';
      title = t('projects:completed');
      icon = <CheckCircleOutlined className="align-middle" />;
    }
    if (suspended) {
      title = t('projects:suspended');
      icon = <LockOutlined className="align-middle" />;
    }
    if (inProgress) {
      type = 'primary';
      title = t('projects:inProgress');
      icon = <LoadingOutlined className="align-middle" />;
    }

    const assignee = useMemo(() => {
      if (!answersSheet.assigneeId && answersSheet.isAssignedToPatient()) {
        return `(${t('patientQuestionnaire')})`;
      }
      let baseName;
      if (!answersSheet.assigneeId) {
        baseName = `[${t('notAssigned')}]`;
      } else {
        const userName = userNames[answersSheet.getAssigneeId()];
        baseName =
          userName ||
          `[${t('forms:userId.label')}: ${answersSheet.assigneeId}]`;
      }
      if (answersSheet.delegatedToPatient) {
        return `${baseName} (${t('patientQuestionnaire')})`;
      }
      return baseName;
    }, [answersSheet, userNames, t]);

    return (
      <StyledBox type={type}>
        <Sidebar
          sidebar={
            <div className="stack-2">
              {completed && (
                <Button
                  data-testid="button-preview"
                  onClick={onPreview}
                  disabled={disabled}
                  block
                >
                  {t('preview')}
                </Button>
              )}
              {!completed && !pastDue && (
                <FormStart answersSheet={answersSheet} disabled={disabled} />
              )}
              <FormActions
                answersSheet={answersSheet}
                disabled={disabled}
                completed={completed && !disabled && !dimmed}
                dashboardsPerspectiveResponses={dashboardsPerspectiveResponses}
                onDashboardsPerspectiveResponsesPreview={
                  handleDashboardsPerspectiveResponsesPreview
                }
              />
            </div>
          }
        >
          <Stack space={1}>
            <div className="stack-0">
              {answersSheet.questionnaireVersion && (
                <p className="text-xs font-semibold">
                  v.{answersSheet.questionnaireVersion}
                </p>
              )}
              <Text.Paragraph>
                <Text.Span>
                  {answersSheet.describeQuestionnaire(questionnaireName)}
                </Text.Span>
                <Text.Span size="small">
                  {' '}
                  {answersSheet.describeAssignee(
                    userNames[answersSheet.getAssigneeId()],
                  )}
                </Text.Span>
              </Text.Paragraph>
            </div>
            <p className="cluster-1 items-end text-sm">
              <Text.Span type={type}>
                {icon} <span className="align-middle">{title}</span>
              </Text.Span>{' '}
              {answersSheet.isCompleted() ? (
                <LocalDate
                  date={answersSheet.completedAt}
                  projectId={answersSheet.projectId}
                  showTimezone
                />
              ) : (
                activityDateEnd && (
                  <LocalDate
                    date={activityDateEnd}
                    projectId={answersSheet.projectId}
                    showTimezone
                  />
                )
              )}
            </p>
          </Stack>
        </Sidebar>
      </StyledBox>
    );
  },
);

const FormsList = ({
  answersSheets,
  userNames,
  questionnaireNames,
  loading,
  onPreview,
  due,
  pastDue,
  activityDateEnd,
  // eslint-disable-next-line react/prop-types
  dashboardsPerspectiveResponses,
  onDashboardsPerspectiveResponsesPreview,
}) => (
  <Stack space={1}>
    {loading ? (
      <Box.Primary>
        <Sidebar
          sidebar={
            <Stack space={1}>
              <div>
                <Skeleton width={125} />
              </div>
              <div>
                <Skeleton width={125} />
              </div>
            </Stack>
          }
        >
          <Stack space={0}>
            <Skeleton width={200} />
            <Skeleton width={250} />
          </Stack>
        </Sidebar>
      </Box.Primary>
    ) : (
      answersSheets.map((answersSheet) => (
        <FormsListItem
          key={answersSheet._id}
          onPreview={onPreview}
          value={answersSheet}
          disabled={!!answersSheet.fake}
          answersSheet={answersSheet}
          answersSheetId={answersSheet._id}
          userNames={userNames}
          questionnaireNames={questionnaireNames}
          due={due}
          pastDue={pastDue}
          activityDateEnd={activityDateEnd}
          dashboardsPerspectiveResponses={dashboardsPerspectiveResponses}
          onDashboardsPerspectiveResponsesPreview={
            onDashboardsPerspectiveResponsesPreview
          }
        />
      ))
    )}
  </Stack>
);

FormsList.propTypes = {
  answersSheets: PropTypes.arrayOf(PropTypes.instanceOf(AnswersSheet)),
  userNames: PropTypes.objectOf(PropTypes.string),
  questionnaireNames: PropTypes.objectOf(PropTypes.string),
  onPreview: PropTypes.func,
  loading: PropTypes.bool,
  due: PropTypes.bool,
  pastDue: PropTypes.bool,
  activityDateEnd: PropTypes.string,
  onDashboardsPerspectiveResponsesPreview: PropTypes.func,
};

FormsList.defaultProps = {
  userNames: {},
  questionnaireNames: {},
  answersSheets: [],
  onPreview: null,
  loading: true,
  due: false,
  pastDue: false,
  activityDateEnd: null,
  onDashboardsPerspectiveResponsesPreview: null,
};

export default FormsList;
