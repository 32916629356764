import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { default as AnswersSheetSelect } from '../../../../../../common/selectors/AnswersSheet';
import { default as QuestionnaireSelect } from '../../../../../../common/selectors/Questionnaire';
import { answersSheetAndQuestionnaire } from '../../../../../../common/api/forms';
import Highlighter from '../../../../../../common/components/Highlighter';
import Dialog from '../../../../../../components/Dialog';
import Skeleton from '../../../../../../components/Skeleton';
import { getPriorityType } from '../helpers';

const KeywordsDialog = ({
  isOpen,
  onCancel,
  referenceNo,
  recipientFullName,
  answersSheetId,
  keywordMatchRulesQuestionId,
  priority,
  keywords,
  createdAt,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { ready } = useDDPSubscription(
    isOpen
      ? answersSheetId &&
          answersSheetAndQuestionnaire.withParams({ answersSheetId })
      : null,
  );
  const answersSheetDocument = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );
  const questionnaireDocument = useSelector(
    QuestionnaireSelect.one().whereIdEquals(
      answersSheetDocument && answersSheetDocument.getQuestionnaireId(),
    ),
  );

  const questionTitle = useMemo(() => {
    if (
      answersSheetDocument &&
      questionnaireDocument &&
      keywordMatchRulesQuestionId
    ) {
      const valueLabelPairs = answersSheetDocument
        .asQuestionnaireResponse()
        .present(questionnaireDocument);

      const valueLabelPair = valueLabelPairs.find(
        ({ questionId }) => questionId === keywordMatchRulesQuestionId,
      );

      return valueLabelPair?.label;
    }

    return '';
  }, [
    keywordMatchRulesQuestionId,
    answersSheetDocument,
    questionnaireDocument,
  ]);

  const freeText = useMemo(() => {
    if (answersSheetDocument && keywordMatchRulesQuestionId) {
      return answersSheetDocument.asQuestionnaireResponse().toFormValues()[
        keywordMatchRulesQuestionId
      ]?.value;
    }

    return '';
  }, [keywordMatchRulesQuestionId, answersSheetDocument]);

  return (
    <Dialog
      title={`${t('reports_one')} #${referenceNo}`}
      visible={isOpen}
      onCancel={onCancel}
    >
      <p>
        <span className="font-medium">{recipientFullName}</span>
        {` ${t('on')} `}
        <span className="font-medium">
          {toIntlDateTimeFormat(language, createdAt, {
            dateStyle: 'short',
            timeStyle: 'short',
          })}
        </span>
        .
      </p>
      <hr className="my-4" />
      <div className="stack-4">
        <span className="text-lg font-medium">{questionTitle}</span>
        {ready && isOpen ? (
          <Highlighter
            type={getPriorityType(priority)}
            text={freeText}
            highlight={keywords}
          />
        ) : (
          <Skeleton className="h-36" />
        )}
      </div>
    </Dialog>
  );
};

export default KeywordsDialog;
