import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import A11yDialog from 'a11y-dialog';
import { QuestionCircle } from 'styled-icons/fa-regular';
import { AnimatePresence, motion } from 'framer-motion';
import { Random } from '@zedoc/random';
import Button from './Button';
import Tooltip from '../common/components/Tooltip';
import {
  UI_SIZES,
  UI_SIZE__XL,
  UI_SIZE__LARGE,
  UI_SIZE__MEDIUM,
  UI_SIZE__SMALL,
} from '../ui/schemata';

// TODO: Probably makes sense to rename testIds for OK and Cancel buttons from
// "button-cancel" and "button-save" to something more dialog specific
// it's been done this way to support currently written E2E tests

// TODO: 'ant-modal-title' and 'ant-modal-body' classes + style={{ wordWrap: 'normal' }}
// being added for e2e tests to pass
// because we currently use a mix of both Ant Modal and this Dialog
// Once we get rid of Ant Modal those can be removed and tests rewritten

const Dialog = ({
  'data-testid': testId,
  title,
  size,
  tooltip,
  visible,
  loading,
  isOkDisabled,
  isCancelDisabled,
  containsAnimations,
  okText,
  onOk,
  onCancel,
  children,
  footer,
  actions,
  isScrollable,
}) => {
  const { t } = useTranslation();

  const id = useRef(Random.id());
  const titleId = `dialog-${id.current}-title`;
  const dialog = useRef();
  const ref = useRef();
  const [shouldTooltipForceUpdate, setShouldTooltipForceUpdate] =
    useState(false);

  useEffect(() => {
    if (ref) {
      dialog.current = new A11yDialog(ref.current);
    }

    return () => dialog.current.destroy();
  }, []);

  useEffect(() => {
    if (visible) {
      dialog.current.show();
    } else {
      dialog.current.hide();
    }
  }, [visible]);

  useEffect(() => {
    dialog.current.on('hide', onCancel);
  }, [onCancel]);

  const handleOnHide = useCallback(() => {
    if (!loading && !isCancelDisabled) {
      dialog.current.hide();
    }
  }, [loading, isCancelDisabled]);

  // TODO: Add prefers reduced motion check
  const dialogVariants = {
    initial: {
      y: '100vh',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '100vh',
    },
  };

  const overlayVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  const maxWidth = useMemo(() => {
    switch (size) {
      case UI_SIZE__SMALL:
        return 'max-w-lg';
      case UI_SIZE__MEDIUM:
        return 'max-w-xl';
      case UI_SIZE__LARGE:
        return 'max-w-2xl';
      case UI_SIZE__XL:
        return 'max-w-3xl';
      default:
        return '';
    }
  }, [size]);

  const contentHeight = useMemo(() => {
    switch (size) {
      case UI_SIZE__SMALL:
        return 'min-h-40';
      case UI_SIZE__MEDIUM:
        return 'min-h-80';
      case UI_SIZE__LARGE:
        return 'min-h-96';
      case UI_SIZE__XL:
        return 'h-144';
      default:
        return '';
    }
  }, [size]);

  const handleDialogAnimationComplete = () => {
    // NOTE: From intitial to "true" then back to "false" just to trigger the update
    setShouldTooltipForceUpdate(true);
    setShouldTooltipForceUpdate(false);
  };

  return createPortal(
    // Container
    <div
      data-testid={testId}
      ref={ref}
      aria-labelledby={titleId}
      aria-hidden="true"
      className={`fixed inset-0 z-30 dialog-container flex justify-center items-center p-6 ${
        visible ? '' : 'pointer-events-none'
      }`}
    >
      <AnimatePresence>
        {visible && (
          <>
            {/* Overlay */}
            <motion.button
              key="overlay"
              aria-label={t('closeDialog')}
              type="button"
              onClick={handleOnHide}
              disabled={loading || isCancelDisabled}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={overlayVariants}
              transition={{
                ease: 'easeInOut',
                duration: 0.3,
              }}
              className="absolute inset-0 bg-black bg-opacity-80 disabled:cursor-not-allowed"
            />
            {/* Dialog */}
            <motion.div
              key="dialog"
              role="document"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={dialogVariants}
              className={`relative ${maxWidth} max-h-full w-full rounded bg-surface dark:bg-neutral-700 flex flex-col`}
              onAnimationComplete={handleDialogAnimationComplete}
              transition={{
                ease: 'easeInOut',
                duration: 0.3,
              }}
            >
              {/* Header */}
              <div className="cluster-2 items-center justify-between px-4 py-3 border-b border-divider">
                <div className="cluster-2 truncate">
                  <h1
                    id={titleId}
                    className="ant-modal-title text-lg font-medium text-body truncate"
                    style={{ wordWrap: 'normal' }}
                  >
                    {title}
                  </h1>
                  {tooltip && (
                    <Tooltip
                      title={tooltip}
                      size="large"
                      forceUpdate={shouldTooltipForceUpdate}
                    >
                      <QuestionCircle className="h-4 w-4" />
                    </Tooltip>
                  )}
                </div>
                <div className="cluster-3">
                  {actions.map((action) => (
                    <Tooltip
                      key={action.key}
                      title={action.label}
                      forceUpdate={shouldTooltipForceUpdate}
                    >
                      <Button
                        type="tertiary"
                        size="medium"
                        icon={action.icon}
                        disabled={action.disabled}
                        onClick={action.onClick}
                      />
                    </Tooltip>
                  ))}
                  <Button
                    data-testid="button-cancel"
                    aria-label={t('closeDialog')}
                    type="tertiary"
                    size="medium"
                    icon={<XIcon />}
                    disabled={loading || isCancelDisabled}
                    onClick={handleOnHide}
                  />
                </div>
              </div>
              {/* Content */}
              <div
                className={`ant-modal-body px-4 py-6 ${contentHeight} ${
                  isScrollable ? 'overflow-y-auto' : 'overflow-y-hidden'
                } flex flex-col ${containsAnimations ? 'relative' : ''}`}
                style={{ wordWrap: 'normal' }}
              >
                {children}
              </div>
              {/* Footer */}
              <div className="flex justify-end px-4 py-3 border-t border-divider">
                {footer || (
                  <Button
                    data-testid="button-ok"
                    type="primary"
                    loading={loading}
                    disabled={isOkDisabled}
                    onClick={onOk || handleOnHide}
                  >
                    {okText || t('ok')}
                  </Button>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>,
    document.body,
  );
};

Dialog.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf(UI_SIZES),
  tooltip: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  isOkDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  containsAnimations: PropTypes.bool,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
  isScrollable: PropTypes.bool,
};

Dialog.defaultProps = {
  'data-testid': 'dialog',
  children: null,
  size: UI_SIZE__MEDIUM,
  tooltip: null,
  visible: false,
  loading: false,
  isOkDisabled: false,
  isCancelDisabled: false,
  containsAnimations: false,
  okText: null,
  onOk: null,
  onCancel: () => {},
  footer: null,
  actions: [],
  isScrollable: true,
};

export default Dialog;
