import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';
import { Field, FieldArray, reduxForm, submit } from 'redux-form';
import { QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS } from '../../../common/constants';
import Stack from '../../../common/components/primitives/Stack';
import { makeJsonSchemaFormValidator } from '../../../utils/errors';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldList from '../../forms/FormFieldList';
import Dialog from '../../Dialog';

const QuestionnaireProperties = ({ member, questionnaireOptions }) => {
  const { t } = useTranslation('forms');

  return (
    <Stack>
      <Field
        data-testid="field-identifier"
        component={FormFieldSelect}
        name={`${member}.identifier`}
        type="text"
        label={t('questionnaire.label')}
        options={questionnaireOptions}
        showSearch
      />
      <Field
        data-testid="field-assignee-type"
        component={FormFieldSelect}
        name={`${member}.assigneeType`}
        type="text"
        label={t('assigneeType.label')}
        options={QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS.map((option) => ({
          ...option,
          label: t(option.label),
        }))}
        showSearch
      />
    </Stack>
  );
};

QuestionnaireProperties.propTypes = {
  member: PropTypes.string.isRequired,
  questionnaireOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const QuestionnairesList = (props) => (
  <FormFieldList
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    properties={QuestionnaireProperties}
  />
);

const DEFAULT_FORM = 'AddAnswersSheets';
const AddAnswersSheetsForm = reduxForm({
  form: DEFAULT_FORM,
  validate: makeJsonSchemaFormValidator({
    type: 'object',
    required: ['questionnaires'],
    properties: {
      questionnaires: {
        type: 'array',
        items: {
          type: 'object',
          required: ['identifier', 'assigneeType'],
          properties: {
            identifier: {
              type: 'string',
              title: 'Questionnaire',
            },
            assigneeType: {
              type: 'string',
              title: 'Assignee type',
            },
          },
        },
        minItems: 1,
      },
    },
  }),
})(({ form, error, handleSubmit, onSubmit, questionnaireOptions }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && <span>{error}</span>}
      <FieldArray
        name="questionnaires"
        label={t('questionnaires')}
        component={QuestionnairesList}
        props={{
          form,
          questionnaireOptions,
        }}
      />
    </form>
  );
});

const AddAnswersSheets = compose(
  withProps(({ milestone }) => ({
    milestone,
    initialValues: {
      questionnaires: [{}],
    },
  })),
  connect(null, (dispatch, { form }) => ({
    doSubmit: () => dispatch(submit(form)),
  })),
  withHandlers({
    onSubmit:
      ({ onSubmit, params }) =>
      (data) =>
        onSubmit({
          ...params,
          ...data,
        }),
  }),
)(
  ({
    form,
    open,
    onCancel,
    onSubmit,
    doSubmit,
    initialValues,
    questionnaireOptions,
  }) => {
    const { t } = useTranslation();

    return (
      <Dialog
        title={t('addQuestionnaires')}
        visible={open}
        // confirmLoading={isConfirmLoading}
        okText={t('submit')}
        onOk={doSubmit}
        onCancel={onCancel}
      >
        <AddAnswersSheetsForm
          form={form}
          onSubmit={onSubmit}
          initialValues={initialValues}
          questionnaireOptions={questionnaireOptions}
        />
      </Dialog>
    );
  },
);

AddAnswersSheets.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  questionnaireOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddAnswersSheets.defaultProps = {
  form: DEFAULT_FORM,
  params: {},
  onCancel: () => {},
  onSubmit: () => {},
};

export default AddAnswersSheets;
