/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '../../../common/components/primitives/Box';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import Text from '../../../common/components/base/Text';
import Button from '../../Button';

const FormFieldList = ({
  label,
  fields,
  disabled,
  properties: Properties,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Sidebar
        sidebar={
          <Button
            data-testid="form-filed-list-add"
            type="primary"
            onClick={() => fields.push({})}
          >
            {t('add')}
          </Button>
        }
      >
        <Stack space={0}>
          <Text.Paragraph data-testid="list-title" importance="high">
            {label}
          </Text.Paragraph>
          <Text.Paragraph>
            {t('totalWithCount', { count: fields.length })}
          </Text.Paragraph>
        </Stack>
      </Sidebar>
      <Stack>
        {fields.map((member, memberIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box.Primary key={memberIndex} borderWidth={1}>
            <Cluster justify="space-between">
              <Text.Span>{memberIndex + 1}</Text.Span>
              <Button
                data-testid="form-filed-list-remove"
                type="danger"
                onClick={() => fields.remove(memberIndex)}
              >
                {t('remove')}
              </Button>
            </Cluster>
            <Properties
              member={member}
              disabled={disabled}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
            />
          </Box.Primary>
        ))}
      </Stack>
    </Stack>
  );
};

FormFieldList.propTypes = {
  properties: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

FormFieldList.defaultProps = {
  disabled: false,
  label: '',
};

export default FormFieldList;
