import React from 'react';
import isArray from 'lodash/isArray';
import trim from 'lodash/trim';
import map from 'lodash/map';
import { escapeRegExp } from '@zedoc/text';

/**
 * @param {string | undefined} type
 * @returns {string | undefined}
 */
const getClassNameByType = (type) => {
  switch (type) {
    case 'danger':
      return 'bg-danger';
    case 'success':
      return 'bg-success text-on-success';
    case 'warning':
      return 'bg-warning text-on-warning';
    case 'info':
      return 'bg-info text-on-info';
    case 'neutral':
      return 'bg-neutral text-on-neutral';
    default:
      return undefined;
  }
};

/**
 * @param {string | undefined} text
 * @param {string | string[] | undefined} highlight
 * @param {string | undefined} type
 * @returns {React.ReactNode}
 */
const renderMarks = (text, highlight, type) => {
  if (!text) {
    return null;
  }
  if (!highlight) {
    return text;
  }
  if (!isArray(highlight)) {
    return renderMarks(text, highlight.split(/\s+/).filter(Boolean), type);
  }
  const regexes = highlight
    .filter(trim)
    .map((chunk) => new RegExp(`${escapeRegExp(chunk)}`, 'i'));
  const chunks = text.split(/\s+/).filter(Boolean);

  return map(chunks, (chunk, index) => {
    for (let i = 0; i <= regexes.length; i += 1) {
      const match = chunk.match(regexes[i]);
      if (match && match.index !== undefined) {
        const j = match.index;
        const k = match[0].length;
        const highlightedText = chunk.substring(j, j + k);
        return [
          index > 0 ? ' ' : null,
          chunk.substring(0, j),
          highlightedText && (
            <mark className={getClassNameByType(type)} key={index}>
              {highlightedText}
            </mark>
          ),
          chunk.substring(j + k),
        ];
      }
    }
    return [index > 0 ? ' ' : null, chunk];
  });
};

export default renderMarks;
