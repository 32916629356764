import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import branding from '../../../../../utils/branding';

const propTypes = {
  onStart: PropTypes.func,
  onPatientStart: PropTypes.func,
  canUsePatientMode: PropTypes.bool,
  canStartNow: PropTypes.bool,
  isAssignedToPatient: PropTypes.bool,
  disabled: PropTypes.bool,
};

const noop = () => {};
const defaultProps = {
  onStart: noop,
  onPatientStart: noop,
  canUsePatientMode: false,
  canStartNow: false,
  isAssignedToPatient: false,
  disabled: false,
};

const FormStart = ({
  onStart,
  onPatientStart,
  canStartNow,
  isAssignedToPatient,
  canUsePatientMode,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!isAssignedToPatient && (
        <Button
          data-testid="button-start-provider-mode"
          type="primary"
          onClick={onStart}
          disabled={disabled || !canStartNow}
        >
          {t('startProviderMode')}
        </Button>
      )}
      <Button
        data-testid="button-start-patient-q"
        type={isAssignedToPatient ? 'primary' : 'ghost'}
        onClick={onPatientStart}
        disabled={disabled || !canStartNow || !canUsePatientMode}
      >
        {isAssignedToPatient
          ? t('start')
          : t('startRecipientMode', {
              context: branding,
            })}
      </Button>
    </>
  );
};

FormStart.propTypes = propTypes;
FormStart.defaultProps = defaultProps;

export default FormStart;
