import React from 'react';
// import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FormFieldText from '../../../common/components/FormFieldText';
import { makeJsonSchemaFormValidator } from '../../../utils/errors';

export const form = 'EditNote';

const EditNoteForm = reduxForm({
  form,
  validate: makeJsonSchemaFormValidator({
    type: 'object',
    required: ['noteContent'],
    properties: {
      noteContent: {
        type: 'string',
        title: 'Note content',
        minLength: 1,
      },
    },
  }),
})(({ handleSubmit, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={FormFieldText}
        name="noteContent"
        label={t('forms:note.label')}
        placeholder={t('forms:note.placeholder')}
        required
        big
      />
    </form>
  );
});

export default EditNoteForm;
