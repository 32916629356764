import { ArrowLeftIcon } from '@heroicons/react/outline';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import forEach from 'lodash/forEach';
import without from 'lodash/without';
import includes from 'lodash/includes';
import { ThemeProvider } from 'styled-components';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { ddp } from '@zedoc/ddp-connector';
import { saveAs } from 'file-saver';
import { connect, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import {
  branch,
  compose,
  renderNothing,
  withHandlers,
  withState,
} from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  EvaluationScope,
  getErrorMessage,
  validateQuestionnaire,
} from '@zedoc/questionnaire';
import { toValueDescriptor, createCleanEmptyValues } from '@zedoc/form-values';
import {
  useSelectorsHub,
  syncFormValues,
  replaceFormErrors,
  clearQuestionnaire,
  validateWithQuestionnaire,
} from '@zedoc/react-questionnaire';
import { property } from '@zedoc/selectors';
import {
  apiAdminGetProjectProperties,
  apiAdminUpdateProject,
} from '../common/api/admin';
import settings from '../common/settings';
import { callMethod } from '../common/utilsClient/ddp/actions';
import Steps from '../common/components/Steps';
import Button from '../components/Button';
import { cancel, notifyError } from '../utils/notify';
import Layout from '../screens/SettingsProject_v2/components/Layout';
import ProjectCard from '../screens/SettingsProject_v2/components/ProjectCard';
import ProjectVariablesForm from '../screens/SettingsProject_v2/components/ProjectVariablesForm';
import PatientPropertiesForm from '../screens/SettingsProject_v2/components/PatientPropertiesForm';
import BindingsForm from '../screens/SettingsProject_v2/components/BindingsForm';
import QuestionnairesForm from '../screens/SettingsProject_v2/components/QuestionnairesForm';
import MilestonesForm from '../screens/SettingsProject_v2/components/MilestonesForm';
import MessagesForm from '../screens/SettingsProject_v2/components/MessagesForm';
import DashboardsForm from '../screens/SettingsProject_v2/components/DashboardsForm';
import EAPPRulesForm from '../screens/SettingsProject_v2/components/EAPPRulesForm';
import TracksForm from '../screens/SettingsProject_v2/components/TracksForm';
import CSVsForm from '../screens/SettingsProject_v2/components/CSVsForm';
import NotificationsForm from '../screens/SettingsProject_v2/components/NotificationsForm';
import InAppContentsForm from '../screens/SettingsProject_v2/components/InAppContentsForm';
import { ReactComponent as TemplateIcon } from '../screens/ProjectWizardTemplates/components/brain.svg';
import { default as patientPropertiesImage } from '../assets/project-wizard-patient-properties.png';
import { default as patientPropertiesDarkImage } from '../assets/project-wizard-patient-properties_dark.png';
import { default as csvImage } from '../assets/project-wizard-csv.png';
import { default as csvDarkImage } from '../assets/project-wizard-csv_dark.png';
import { default as tracksImage } from '../assets/project-wizard-tracks.png';
import { default as tracksDarkImage } from '../assets/project-wizard-tracks_dark.png';
import { default as questionnairesImage } from '../assets/project-wizard-questionnaires.png';
import { default as questionnairesDarkImage } from '../assets/project-wizard-questionnaires_dark.png';
import { default as milestonesImage } from '../assets/project-wizard-milestones.png';
import { default as milestonesDarkImage } from '../assets/project-wizard-milestones_dark.png';
import { default as communicationImage } from '../assets/project-wizard-communication.png';
import { default as communicationDarkImage } from '../assets/project-wizard-communication_dark.png';
import { default as eappRulesImages } from '../assets/project-wizard-eapp-rules.png';
import { default as eappRulesDarkImages } from '../assets/project-wizard-eapp-rules_dark.png';
import { default as dashboardsImage } from '../assets/project-wizard-dashboards.png';
import { default as dashboardsDarkImage } from '../assets/project-wizard-dashboards_dark.png';
import { default as inAppContentsImage } from '../assets/project-wizard-in-app-contents.png';
import { default as inAppContentsDarkImage } from '../assets/project-wizard-in-app-contents_dark.png';
import themeProjectWizard from '../ui/themeProjectWizard';
import GlobalStyleProjectWizard from '../ui/GlobalStyleProjectWizard';
import cleanProjectProperties from '../utils/cleanProjectProperties';
import ProjectWizard from '../components/ProjectWizard';
import PreviewImage from '../screens/SettingsProject_v2/components/PreviewImage';

const {
  features: { enableEApp, enableSurveyLinks },
} = settings.public;

const pickErrors = (questionnaire, formErrors, questionIds) => {
  return pickBy(formErrors, (_, questionId) => {
    return questionnaire.someAncestorOrSelf(questionId, (question) => {
      return includes(questionIds, question.id);
    });
  });
};

const cleanEmptyValues = createCleanEmptyValues({
  keepEmptyArrays: true,
});

const identity = (x) => x;
const constant = (x) => () => x;
const selectQuestionnaire = constant(ProjectWizard);
const selectQuestionnaireId = createSelector(
  selectQuestionnaire,
  (questionnaire) => questionnaire && questionnaire.id,
);

// FIXME: We need this data for proper error validation. However,
//        this information is unfortunately repeated in the individual
//        components implementation details, apart from ProjectVariablesForm.
const keyToQuestionIds = {
  'patient-authentication': [
    'ab_patient_authentication',
    'aba_require_patient_authentication',
    'abd_editable_aba',
    'abb_when_authentication_id_is_missing',
    'abc_patient_auth_tooltips',
  ],
  'project-variables': [
    'a1_name',
    'a3_description',
    'a6_timezone',
    'a9_fallback_language',
    'a10_other_supported_languages',
    'a7_logo_url',
    'a8_billing_code',
    ...(enableSurveyLinks ? ['a9_allow_use_of_survey_links'] : []),
  ],
  'patient-properties': ['c_variables', 'g_message_template_bindings'],
  csv: ['am_csv'],
  tracks: ['ai_tracks'],
  questionnaires: ['b_questionnaires'],
  milestones: ['d_milestones'],
  communication: ['f_message_templates', 'e_notifications'],
  'in-app-contents': ['aq_in_app_contents'],
  'eapp-rules': ['m_eapp_rules'],
  dashboards: ['k_dashboards'],
};

const RouterProjectWizard = compose(
  withRouter,
  connect(() =>
    createStructuredSelector({
      activeKey: (state, props) =>
        props.match && props.match.params && props.match.params.tab,
    }),
  ),
  // TODO: Freeze questionnaireId on initial load, to ensure
  //       that it does not change accidentally if new Q. is published.
  withState('variables', 'setVariables', {}),
  withState('isInitializing', 'setIsInitializing', true),
  withState('completedSteps', 'setCompletedSteps', []),
  withState('erredSteps', 'setErredSteps', []),
  withState('shouldUseTemplateBindings', 'setShouldUseTemplateBindings', false),
  withState(
    'canDisableTemplateBindings',
    'setCanDisableTemplateBindings',
    true,
  ),
  withState('shouldUseCSV', 'setShouldUseCSV', false),
  withState('canDisableCSV', 'setCanDisableCSV'),
  withState('shouldUseTracks', 'setShouldUseTracks', false),
  withState('canDisableTracks', 'setCanDisableTracks', true),
  withState('shouldUseEAPPRules', 'setShouldUseEAPPRules', false),
  withState('canDisableEAPPRules', 'setCanDisableEAPPRules', true),
  ddp({
    selectors: () =>
      createStructuredSelector({
        projectId: property('match.params.projectId'),
        questionnaire: selectQuestionnaire,
        questionnaireId: selectQuestionnaireId,
      }),
    mutations: {
      reloadProjectProperties:
        ({
          dispatch,
          projectId,
          setVariables,
          setShouldUseTemplateBindings,
          setCanDisableTemplateBindings,
          setShouldUseCSV,
          setCanDisableCSV,
          setShouldUseTracks,
          setCanDisableTracks,
          setShouldUseEAPPRules,
          setCanDisableEAPPRules,
          setErredSteps,
          completedSteps,
          setCompletedSteps,
        }) =>
        (questionnaire, nextProperties, validate) =>
          (nextProperties
            ? Promise.resolve({
                properties: nextProperties,
              })
            : dispatch(
                callMethod(apiAdminGetProjectProperties, {
                  projectId,
                }),
              )
          ).then(({ properties }) => {
            const variables = {
              ...questionnaire.getDefaultVariables({
                expanded: true,
              }),
              project: toValueDescriptor(properties),
              value_set_questionnaires: toValueDescriptor({
                identifier: `projects/${projectId}/questionnaires`,
              }),
            };
            const scope = new EvaluationScope({
              questionnaire,
              variables,
            });
            const formValues = scope.getInitialValues();

            let formErrors;
            if (validate) {
              formErrors = validateQuestionnaire(questionnaire, formValues, {
                variables,
              });
              const fatalFormErrors = validateQuestionnaire(
                questionnaire,
                formValues,
                {
                  variables,
                  // NOTE: We are assuming all fields to be optional, because we don't want
                  //       to terminate the process if some fields are missing, e.g. because
                  //       of project wizard version mismatch.
                  skipMissing: true,
                },
              );
              if (fatalFormErrors) {
                throw new Error(getErrorMessage(fatalFormErrors));
              }
            }
            dispatch(
              syncFormValues(`projectSettings::${projectId}`, formValues),
            );
            setVariables(variables);

            const erredSteps = [];

            if (formErrors) {
              dispatch(
                replaceFormErrors(`projectSettings::${projectId}`, formErrors),
              );
              forEach(keyToQuestionIds, (questionIds, key) => {
                const errors = pickErrors(
                  questionnaire,
                  formErrors,
                  questionIds,
                );
                if (!isEmpty(errors)) {
                  erredSteps.push(key);
                }
              });
            }

            setErredSteps(erredSteps);

            const validatedSteps = [];

            if (
              properties &&
              ((properties.variables && properties.variables.length > 1) ||
                (properties.messageTemplates &&
                  properties.messageTemplateBindings.length > 0))
            ) {
              validatedSteps.push('patient-properties');
            }

            // Detect if Message Template Bindings are in use, and prevent the user from disabling them if they are
            if (
              properties &&
              properties.messageTemplateBindings &&
              properties.messageTemplateBindings.length > 0
            ) {
              setShouldUseTemplateBindings(true);
              setCanDisableTemplateBindings(false);
            } else {
              setCanDisableTemplateBindings(true);
            }

            // Detect if CSVs are in use, and prevent the user from disabling them if they are
            if (
              properties &&
              properties.csvSchemata &&
              properties.csvSchemata.length > 0
            ) {
              validatedSteps.push('csv');
              setShouldUseCSV(true);
              setCanDisableCSV(false);
            } else {
              setCanDisableCSV(true);
            }

            // Detect if Tracks are in use, and prevent the user from disabling them if they are
            if (
              properties &&
              properties.tracks &&
              properties.tracks.length > 1
            ) {
              validatedSteps.push('tracks');
              setShouldUseTracks(true);
              setCanDisableTracks(false);
            } else {
              setCanDisableTracks(true);
            }

            // Detect if EAPP Rules are in use, and prevent the user from disabling them if they are
            if (
              properties &&
              properties.eappRules &&
              properties.eappRules.length > 0
            ) {
              validatedSteps.push('eapp-rules');
              setShouldUseEAPPRules(true);
              setCanDisableEAPPRules(false);
            } else {
              setCanDisableEAPPRules(true);
            }

            if (
              properties &&
              properties.questionnaires &&
              properties.questionnaires.length > 0
            ) {
              validatedSteps.push('questionnaires');
            }

            if (
              properties &&
              properties.milestones &&
              properties.milestones.length > 0
            ) {
              validatedSteps.push('milestones');
            }

            if (
              properties &&
              ((properties.messageTemplates &&
                properties.messageTemplates.length > 0) ||
                (properties.notifications &&
                  properties.notifications.length > 0))
            ) {
              validatedSteps.push('communication');

              // only assume dashboards are also done if this is complete.
              if (properties.dashboards && properties.dashboards.length > 0) {
                validatedSteps.push('dashboards');
              }
            }

            if (
              properties &&
              properties.inAppContents &&
              properties.inAppContents.length > 0
            ) {
              validatedSteps.push('in-app-contents');
            }

            setCompletedSteps([...completedSteps, ...validatedSteps]); // FIXME deduplicate array entries
          }),
    },
  }),
  branch(({ questionnaire }) => !!questionnaire, identity, renderNothing),
  withHandlers({
    handleOnChange:
      ({ history, projectId }) =>
      (value) =>
        history.push(`${`/settings/projects/${projectId}/${value}`}`),
    handleOpen:
      ({ reloadProjectProperties, questionnaire }) =>
      (rawFileContent) =>
        Promise.resolve()
          .then(() => {
            let properties;
            try {
              properties = JSON.parse(rawFileContent);
              properties = cleanProjectProperties(properties);

              if (!enableEApp && properties.eappRules) {
                delete properties.eappRules;
              }
            } catch (err) {
              throw new Error('We are sorry, this is not a valid JSON file');
            }

            // NOTE: Force validation of the properties.
            return reloadProjectProperties(questionnaire, properties, true);
          })
          .catch(notifyError()),
    handleCopy:
      ({ dispatch, projectId, questionnaire, variables }) =>
      () =>
        Promise.resolve()
          .then(() =>
            dispatch(
              validateWithQuestionnaire(
                `projectSettings::${projectId}`,
                questionnaire,
                {
                  dryRun: true,
                },
              ),
            ),
          )
          .then(({ formValues }) => {
            const scope = new EvaluationScope({
              questionnaire,
              variables,
              answers: formValues,
            });
            return scope.evaluateVariables();
          })
          .then(({ project }) => {
            const properties = {
              ...project,
              editorVersion: questionnaire.id,
            };
            const blob = new Blob([JSON.stringify(properties, null, 2)], {
              type: 'application/json',
            });
            saveAs(blob, 'project.json');
          })
          .catch(notifyError()),
    handleSave:
      ({
        dispatch,
        projectId,
        questionnaire,
        variables,
        activeKey,
        erredSteps,
        setErredSteps,
        reloadProjectProperties,
      }) =>
      (callback) =>
        Promise.resolve()
          .then(() =>
            dispatch(
              validateWithQuestionnaire(
                `projectSettings::${projectId}`,
                questionnaire,
                {
                  dryRun: true,
                },
              ),
            ),
          )
          .then((validationResult) => {
            let { formErrors } = validationResult;
            let shouldCancel = false;
            if (!isEmpty(formErrors)) {
              dispatch(
                replaceFormErrors(`projectSettings::${projectId}`, formErrors),
              );
              formErrors = pickErrors(
                questionnaire,
                formErrors,
                keyToQuestionIds[activeKey],
              );
              if (!isEmpty(formErrors)) {
                throw new Error('Cannot proceed because of validation errors');
              }
              shouldCancel = true;
            }
            if (isEmpty(formErrors)) {
              setErredSteps(without(erredSteps, activeKey));
            }
            if (shouldCancel) {
              notifyError({
                useWarning: true,
              })(
                'Due to validation errors, we cannot save your configuration yet',
              );
              if (callback) {
                callback();
              }
              // NOTE: Throwing "cancel" will skip the notifyError() at the end of the promises chain.
              throw cancel;
            }
            return validationResult;
          })
          .then(({ formValues }) => {
            const scope = new EvaluationScope({
              questionnaire,
              variables,
              answers: formValues,
            });
            return scope.evaluateVariables();
          })
          .then(({ project }) =>
            dispatch(
              // NOTE: The api endpoint does not accept null values in general, so let's
              //       double check that they're all cleaned before submitting.
              callMethod(
                apiAdminUpdateProject,
                cleanEmptyValues({
                  projectId,
                  ...project,
                }),
              ),
            ),
          )
          // NOTE: I don't think it's necessary to reload the properties from server
          //       after successful save. Given that this will happen on every "NEXT",
          //       it probably makes sense to skip this step for better performance.
          .then(() => reloadProjectProperties(questionnaire))
          .then(callback)
          .catch(notifyError()),
  }),
)(
  ({
    history,

    activeKey,
    handleOnChange,
    projectId,
    variables,
    questionnaire,

    handleSave,
    handleOpen,
    handleCopy,

    dispatch,
    reloadProjectProperties,
    setIsInitializing,
    erredSteps,
    completedSteps,
    setCompletedSteps,

    shouldUseTemplateBindings,
    setShouldUseTemplateBindings,
    canDisableTemplateBindings,

    shouldUseCSV,
    setShouldUseCSV,
    canDisableCSV,

    shouldUseTracks,
    setShouldUseTracks,
    canDisableTracks,

    shouldUseEAPPRules,
    setShouldUseEAPPRules,
    canDisableEAPPRules,
  }) => {
    const name = `projectSettings::${projectId}`;

    const editorState = useSelectorsHub({
      name,
      questionnaire,
    });

    useEffect(() => {
      reloadProjectProperties(questionnaire, null, true)
        .catch(notifyError())
        .then(() => setIsInitializing(false));
      return () => {
        dispatch(clearQuestionnaire(name));
      };
    }, [
      name,
      dispatch,
      questionnaire,
      reloadProjectProperties,
      setIsInitializing,
    ]);

    const shouldShowCSV = shouldUseCSV || includes(erredSteps, 'csv');
    const shouldShowTracks = shouldUseTracks || includes(erredSteps, 'tracks');
    const shouldShowEAPPRules =
      enableEApp && (shouldUseEAPPRules || includes(erredSteps, 'eapp-rules'));

    // FIXME: Map them properly to 'tabs'
    const routes = [
      'project-variables',
      'patient-properties',
      shouldShowCSV && 'csv',
      shouldShowTracks && 'tracks',
      'questionnaires',
      'milestones',
      'communication',
      'in-app-contents',
      shouldShowEAPPRules && 'eapp-rules',
      'dashboards',
    ].filter((route) => route);
    const hasPrevTab = !!routes.indexOf(activeKey);
    const hasNextTab = routes.length - 1 !== routes.indexOf(activeKey);
    const handleOpenNextTab = () => {
      const currentTabIndex = routes.indexOf(activeKey);
      const nextTabValue = routes[currentTabIndex + 1];
      const currentTabValue = routes[currentTabIndex];

      handleSave(() => handleOnChange(nextTabValue));

      // TODO: Make sure it doesn't contain same values
      setCompletedSteps([...completedSteps, currentTabValue]);
    };
    const handleOpenPrevTab = () => {
      const currentTabIndex = routes.indexOf(activeKey);
      const prevTabValue = routes[currentTabIndex - 1];

      if (prevTabValue) {
        handleSave(() => handleOnChange(prevTabValue));
      }
    };
    const handleOnComplete = () => {
      handleSave(() => history.push('/settings/projects'));
    };

    const getStatus = (value) => {
      if (activeKey === value) {
        return 'process';
      }

      if (includes(erredSteps, value)) {
        return 'error';
      }

      // if you refresh halfway through the process, state can be lost.
      // assume previous pages are complete, as you can't pass them if they're not.
      if (routes.indexOf(value) <= routes.indexOf(activeKey)) {
        return 'finish';
      }

      if (includes(completedSteps, value)) {
        return 'finish';
      }

      return 'default';
    };

    // FIXME: Translate
    const steps = [
      {
        title: 'Project',
        status: getStatus('project-variables'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/project-variables`),
          ),
      },
      {
        title: 'Patient Properties',
        status: getStatus('patient-properties'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/patient-properties`),
          ),
      },
      shouldShowCSV && {
        title: 'CSV',
        status: getStatus('csv'),
        onClick: () =>
          handleSave(() => history.push(`/settings/projects/${projectId}/csv`)),
      },
      shouldShowTracks && {
        title: 'Tracks',
        status: getStatus('tracks'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/tracks`),
          ),
      },
      {
        title: 'Questionnaires',
        status: getStatus('questionnaires'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/questionnaires`),
          ),
      },
      {
        title: 'Milestones',
        status: getStatus('milestones'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/milestones`),
          ),
      },
      {
        title: 'Patient Communication',
        status: getStatus('communication'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/communication`),
          ),
      },
      {
        title: 'In-App Contents',
        status: getStatus('in-app-contents'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/in-app-contents`),
          ),
      },
      // {
      //   title: 'Actionable Insights',
      // },
      shouldShowEAPPRules && {
        title: 'EAPP Rules',
        status: getStatus('eapp-rules'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/eapp-rules`),
          ),
      },
      {
        title: 'Dashboards',
        status: getStatus('dashboards'),
        onClick: () =>
          handleSave(() =>
            history.push(`/settings/projects/${projectId}/dashboards`),
          ),
      },
    ].filter((step) => step);

    const projectName = useSelector(
      editorState.select.variable('project.name'),
    );
    const projectDescription = useSelector(
      editorState.select.variable('project.description'),
    );
    const projectTimezone = useSelector(
      editorState.select.variable('project.timezone'),
    );
    const logoUrl = useSelector(editorState.select.variable('project.logoUrl'));

    return (
      <ThemeProvider theme={themeProjectWizard}>
        <GlobalStyleProjectWizard
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div className="p-6 rounded-md bg-pw2-header dark:bg-disabled cluster-2 justify-between items-center">
            <div className="cluster-2 items-center">
              <Button
                data-testid="page-button-back"
                type="tertiary"
                icon={<ArrowLeftIcon />}
                onClick={() => history.push('/settings/projects')}
              />
              <TemplateIcon className="w-8 h-8" />
              <h1
                data-testid="page-header"
                className="text-lg font-medium mt-0"
              >
                {projectName}
              </h1>
            </div>
            <Steps steps={steps} />
          </div>
          <div
            style={{
              overflow: 'hidden',
              flex: '1',
            }}
          >
            <Switch>
              <Route
                exact
                path={`/settings/projects/${projectId}/project-variables`}
                render={() => (
                  <Layout
                    title="Project Variables"
                    explanations={[
                      {
                        explanationKey:
                          'projectWizard:projectVariables.description',
                        icon: 'settings',
                      },
                    ]}
                    forms={[
                      <ProjectVariablesForm
                        key="project-variables"
                        projectVariablesQuestionsIds={
                          keyToQuestionIds['project-variables']
                        }
                        patientAuthQuestionsIds={
                          keyToQuestionIds['patient-authentication']
                        }
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                        shouldUseTemplateBindings={shouldUseTemplateBindings}
                        setShouldUseTemplateBindings={
                          setShouldUseTemplateBindings
                        }
                        canDisableTemplateBindings={canDisableTemplateBindings}
                        shouldUseCSV={shouldUseCSV}
                        setShouldUseCSV={setShouldUseCSV}
                        canDisableCSV={canDisableCSV}
                        shouldUseTracks={shouldUseTracks}
                        setShouldUseTracks={setShouldUseTracks}
                        canDisableTracks={canDisableTracks}
                        shouldUseEAPPRules={shouldUseEAPPRules}
                        setShouldUseEAPPRules={setShouldUseEAPPRules}
                        canDisableEAPPRules={canDisableEAPPRules}
                        handleOpen={handleOpen}
                        handleCopy={handleCopy}
                      />,
                    ]}
                    preview={
                      <ProjectCard
                        name={projectName}
                        description={projectDescription}
                        timezone={projectTimezone}
                        logoUrl={logoUrl}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/patient-properties`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey:
                          'projectWizard:recipientVariables.explanation',
                        shortExplanationKey:
                          'projectWizard:recipientVariables.shortExplanation',
                        icon: 'person',
                      },
                      shouldUseTemplateBindings
                        ? {
                            explanationKey:
                              'projectWizard:messageTemplatesBindings.description',
                            icon: 'bindings',
                          }
                        : null,
                    ]}
                    forms={[
                      <PatientPropertiesForm
                        key="patient-properties"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                      shouldUseTemplateBindings ? (
                        <BindingsForm
                          key="bindings"
                          projectId={projectId}
                          variables={variables}
                          questionnaire={questionnaire}
                        />
                      ) : null,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Patient Properties"
                        src={patientPropertiesImage}
                        darkSrc={patientPropertiesDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/csv`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey: 'projectWizard:csv.description',
                        icon: 'csv',
                      },
                    ]}
                    forms={[
                      <CSVsForm
                        key="CSVs"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="CSV"
                        src={csvImage}
                        darkSrc={csvDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/tracks`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey: 'projectWizard:tracks.explanation',
                        shortExplanationKey:
                          'projectWizard:tracks.shortExplanation',
                        icon: 'tracks',
                      },
                    ]}
                    forms={[
                      <TracksForm
                        key="tracks"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Tracks"
                        src={tracksImage}
                        darkSrc={tracksDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/questionnaires`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey:
                          'projectWizard:questionnaires.description',
                        icon: 'question',
                      },
                    ]}
                    forms={[
                      <QuestionnairesForm
                        key="questionnaires"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Questionnaires"
                        src={questionnairesImage}
                        darkSrc={questionnairesDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/milestones`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey: 'projectWizard:milestones.description',
                        icon: 'milestones',
                      },
                    ]}
                    forms={[
                      <MilestonesForm
                        key="milestones"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Milestones"
                        src={milestonesImage}
                        darkSrc={milestonesDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/communication`}
                render={() => (
                  <Layout
                    titles={['Messages', 'Notifications']}
                    explanations={[
                      {
                        explanationKey: 'projectWizard:messages.explanation',
                        shortExplanationKey:
                          'projectWizard:messages.shortExplanation',
                        icon: 'message',
                      },
                      {
                        explanationKey:
                          'projectWizard:notifications.explanation',
                        shortExplanationKey:
                          'projectWizard:notifications.shortExplanation',
                        icon: 'sms',
                      },
                    ]}
                    forms={[
                      <MessagesForm
                        key="messages"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                      <NotificationsForm
                        key="notifications"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Communication"
                        src={communicationImage}
                        darkSrc={communicationDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/eapp-rules`}
                render={() =>
                  enableEApp && (
                    <Layout
                      explanations={[
                        {
                          explanationKey: 'projectWizard:eappRules.explanation',
                          shortExplanationKey:
                            'projectWizard:eappRules.shortExplanation',
                          icon: 'dashboard',
                        },
                      ]}
                      forms={[
                        <EAPPRulesForm
                          key="eapp"
                          projectId={projectId}
                          variables={variables}
                          questionnaire={questionnaire}
                        />,
                      ]}
                      preview={
                        <PreviewImage
                          alt="EAPP Rules"
                          src={eappRulesImages}
                          darkSrc={eappRulesDarkImages}
                        />
                      }
                      onSave={handleSave}
                      hasPrevTab={hasPrevTab}
                      hasNextTab={hasNextTab}
                      onOpenPrevTab={handleOpenPrevTab}
                      onOpenNextTab={handleOpenNextTab}
                      onComplete={handleOnComplete}
                    />
                  )
                }
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/dashboards`}
                render={() => (
                  <Layout
                    explanations={[
                      {
                        explanationKey: 'projectWizard:dashboards.explanation',
                        shortExplanationKey:
                          'projectWizard:dashboards.shortExplanation',
                        icon: 'dashboard',
                      },
                    ]}
                    forms={[
                      <DashboardsForm
                        key="dashboards"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="Dashboards"
                        src={dashboardsImage}
                        darkSrc={dashboardsDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                    onComplete={handleOnComplete}
                  />
                )}
              />
              <Route
                exact
                path={`/settings/projects/${projectId}/in-app-contents`}
                render={() => (
                  <Layout
                    titles={['In-App Contents']}
                    explanations={[
                      {
                        explanationKey:
                          'projectWizard:inAppContents.explanation',
                        shortExplanationKey:
                          'projectWizard:inAppContents.shortExplanation',
                        icon: 'image',
                      },
                    ]}
                    forms={[
                      <InAppContentsForm
                        key="inAppContents"
                        projectId={projectId}
                        variables={variables}
                        questionnaire={questionnaire}
                      />,
                    ]}
                    preview={
                      <PreviewImage
                        alt="In-App Contents"
                        src={inAppContentsImage}
                        darkSrc={inAppContentsDarkImage}
                      />
                    }
                    onSave={handleSave}
                    hasPrevTab={hasPrevTab}
                    hasNextTab={hasNextTab}
                    onOpenPrevTab={handleOpenPrevTab}
                    onOpenNextTab={handleOpenNextTab}
                  />
                )}
              />
            </Switch>
          </div>
        </GlobalStyleProjectWizard>
      </ThemeProvider>
    );
  },
);

export default RouterProjectWizard;
