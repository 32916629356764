import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Box from '../../../../common/components/primitives/Box';
import Text from '../../../../common/components/base/Text';
import List from '../../../../common/components/List';
import SignedNote from '../../../../common/models/SignedNote';
import Dialog from '../../../../components/Dialog';

const propTypes = {
  open: PropTypes.bool,
  notes: PropTypes.arrayOf(PropTypes.instanceOf(SignedNote)),
  userNames: PropTypes.objectOf(PropTypes.string),
  onAddNote: PropTypes.func,
  onClose: PropTypes.func,
};

const noop = () => {};
const defaultProps = {
  open: false,
  notes: [],
  userNames: {},
  onAddNote: noop,
  onClose: noop,
};

const NotesModal = ({ open, notes, userNames, onAddNote, onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Dialog
      title={t('notes')}
      onOk={onAddNote}
      okText={t('addNote')}
      visible={open}
      onCancel={onClose}
    >
      {notes.length > 0 ? (
        <Box.Primary borderWidth={1}>
          <List
            dataSource={notes}
            renderItem={(note, idx) => (
              <div key={idx}>
                <Text.Paragraph importance="high">
                  {`${note.renderName(userNames)} ${toIntlDateTimeFormat(
                    language,
                    note.updatedAt || note.createdAt,
                    { dateStyle: 'short', timeStyle: 'short' },
                  )}`}
                </Text.Paragraph>
                <Text.Paragraph>{note.renderContent(userNames)}</Text.Paragraph>
              </div>
            )}
          />
        </Box.Primary>
      ) : (
        t('forms:note.none')
      )}
    </Dialog>
  );
};

NotesModal.propTypes = propTypes;
NotesModal.defaultProps = defaultProps;

export default NotesModal;
