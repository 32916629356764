export default function getRealIpAddress(connection) {
  let ip;

  if (connection.httpHeaders) {
    if (connection.httpHeaders['x-real-ip']) {
      ip = connection.httpHeaders['x-real-ip'];
    } else if (connection.httpHeaders['x-forwarded-for']) {
      ip = connection.httpHeaders['x-forwarded-for'];
    }
  }

  if (connection.clientAddress) {
    ip = connection.clientAddress;
  }

  if (ip && ip.includes(',')) {
    ip = ip.split(',')[0].trim();
  }

  return ip;
}
