import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { StickyNote } from 'styled-icons/fa-regular';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Badge from '../../../../common/components/Badge';
import Menu from '../../../../common/components/Menu';
import Dropdown from '../../../../common/components/Dropdown';
import Button from '../../../../components/Button';
import { default as ParticipationSelect } from '../../../../common/selectors/Participation';
import PatientRecord from '../../../../common/models/PatientRecord';
import { SearchHighlighter } from '../../../../components/inputs/Search';
import DischargeComments from '../../../../components/dialogs/DischargeComments';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../../../common/permissions';
import usePermission from '../../../../utils/usePermission';
import useElementTruncated from '../../../../utils/useElementTruncated';
import Tooltip from '../../../../common/components/Tooltip';
import useProjectVariablesGender from '../../../../utils/useProjectVariablesGender';

const propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  shouldHideMeta: PropTypes.bool,
};

const defaultProps = {
  patientRecord: null,
  actions: [],
  shouldHideMeta: false,
};

const StyledBadge = styled(Badge)`
  position: relative;
  z-index: 5;
  pointer-events: auto;
`;

const ActivitiesPatientProfile = ({
  patientRecord: patientRecordOrNothing,
  actions,
  shouldHideMeta,
}) => {
  const { t } = useTranslation();
  const patientRecord = useMemo(
    () => patientRecordOrNothing || new PatientRecord({}),
    [patientRecordOrNothing],
  );

  const [isDischargeCommentsModalVisible, setDischargeCommentsModalVisible] =
    useState(false);
  const handleOnDischargeCommentsModalOpen = () =>
    setDischargeCommentsModalVisible(true);
  const handleOnDischargeCommentsModalClose = () =>
    setDischargeCommentsModalVisible(false);

  const gender = useProjectVariablesGender(patientRecord);

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: patientRecord && patientRecord.getDomains(),
  });

  const participation = useSelector(
    ParticipationSelect.one().whereIdEquals(patientRecord.participationId),
  );
  const isDischarged = participation?.isDischarged();

  const getName = () => {
    if (!canSeePII) {
      return patientRecord.getTruncatedRecipientId();
    }

    return (
      patientRecord.getFullName() ||
      patientRecord.getIdentifier() ||
      patientRecord.getPrimaryIdentifier() ||
      patientRecord.getStudyNo() ||
      patientRecord.getTruncatedRecipientId()
    );
  };

  const display = patientRecord.getFullName() || patientRecord.getIdentifier();

  const { ref, isElementTruncated } = useElementTruncated();

  return (
    <Sidebar
      data-testid={`profile-${display}`}
      space={2}
      side="left"
      sidebar={
        <Dropdown
          overlay={
            <Menu>
              {actions.map(({ title, icon, onClick, disabled, type }, i) => {
                if (type === 'divider') {
                  // eslint-disable-next-line react/no-array-index-key
                  return <Menu.Divider key={i} />;
                }

                return (
                  <Menu.Item
                    data-testid={`profile-menu-item-${title}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    icon={icon}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    {title}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          disabled={isDischarged}
          trigger={['click']}
        >
          <Button
            data-testid="button-profile-menu"
            type="tertiary"
            icon={<DotsVerticalIcon />}
          />
        </Dropdown>
      }
    >
      <div className="stack-1 break-words">
        <div
          style={
            shouldHideMeta
              ? {
                  display: 'none',
                }
              : {}
          }
        >
          <Tooltip title={isElementTruncated ? getName() : ''}>
            <Link
              ref={ref}
              data-testid={`button-patient-${display}`}
              to={`/patients/${patientRecord.recipientId}`}
              importance="high"
              // For some reason interactive class doesn't work on a non block element
              className="interactive-over-overlay font-medium text-primary-800 hover:underline block truncate"
            >
              <SearchHighlighter text={getName()} />
            </Link>
          </Tooltip>
        </div>
        <div className="cluster-2">
          <div className="stack-0">
            <div className="cluster-1">
              {gender && (
                <div
                  className={
                    isDischarged
                      ? 'text-on-disabled pointer-events-none select-none'
                      : ''
                  }
                  style={
                    shouldHideMeta
                      ? {
                          opacity: 0,
                          pointerEvents: 'none',
                        }
                      : {}
                  }
                >
                  <span className="text-sm">{gender}</span>
                </div>
              )}
              {gender && patientRecord.getAge() && (
                <div className="text-divider">|</div>
              )}
              {patientRecord.getAge() && (
                <div
                  className={
                    isDischarged
                      ? 'text-on-disabled pointer-events-none select-none'
                      : ''
                  }
                  style={
                    shouldHideMeta
                      ? {
                          opacity: 0,
                          pointerEvents: 'none',
                        }
                      : {}
                  }
                >
                  <span className="text-sm" data-testid="patient-age">
                    {t('yearsWithCount', { count: patientRecord.getAge() })}
                  </span>
                </div>
              )}
            </div>
            {patientRecord.getStudyNo() && (
              <SearchHighlighter
                className={`text-sm ${
                  isDischarged
                    ? 'text-on-disabled pointer-events-none select-none'
                    : ''
                }`}
                text={patientRecord.getStudyNo()}
              />
            )}
            {patientRecord.getMRN() && (
              <span>
                <span className="text-xs font-medium">MRN: </span>
                <span className="text-sm">
                  {patientRecord
                    .getMRN()
                    .map((obj) => Object.values(obj).join('/'))
                    .join(', ')}
                </span>
              </span>
            )}
          </div>
          <div>
            {isDischarged && !isEmpty(participation.getComments()) && (
              <>
                <StyledBadge
                  type="info"
                  count={participation.getComments()?.length}
                  offsetX={-8}
                  offsetY={8}
                >
                  <Button
                    data-testid="button-discharge-comments"
                    type="tertiary"
                    icon={<StickyNote />}
                    onClick={handleOnDischargeCommentsModalOpen}
                  />
                </StyledBadge>
                <DischargeComments
                  visible={isDischargeCommentsModalVisible}
                  participationId={patientRecord.participationId}
                  projectId={patientRecord.projectId}
                  recipientId={patientRecord.recipientId}
                  onClose={handleOnDischargeCommentsModalClose}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

ActivitiesPatientProfile.propTypes = propTypes;
ActivitiesPatientProfile.defaultProps = defaultProps;

export default ActivitiesPatientProfile;
