import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ContentCopy } from 'styled-icons/material';
import { Markdown } from '@zedoc/react-markdown';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import List from '../../../common/components/List';
import Cluster from '../../../common/components/primitives/Cluster';
import copyToClipboard from '../../../common/utilsClient/copyToClipboard';
import Button from '../../Button';
import Dialog from '../../Dialog';

const PreviewTabularData = compose()(
  ({ open, title, onClose, subscriptionsReady, dataSource, footer }) => {
    const handleOnCopyRow = (item) =>
      copyToClipboard(`${item.title}
${item.description}
`);

    return (
      <Dialog title={title} footer={footer} visible={open} onCancel={onClose}>
        <Box.Primary borderWidth={1}>
          <List
            dataSource={dataSource}
            loading={!subscriptionsReady}
            pageSize={5}
            renderItem={(item, idx) => (
              <Cluster justify="space-between" wrap="nowrap">
                <Stack key={idx} space={0}>
                  <Markdown
                    data-testid="title"
                    source={item.title}
                    className="text-secondary"
                  />
                  <Markdown
                    data-testid="description"
                    source={item.description}
                  />
                </Stack>
                <Button
                  type="ghost"
                  icon={<ContentCopy />}
                  onClick={() => handleOnCopyRow(item)}
                />
              </Cluster>
            )}
          />
        </Box.Primary>
      </Dialog>
    );
  },
);

PreviewTabularData.propTypes = {
  open: PropTypes.bool.isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func,
  subscriptionsReady: PropTypes.bool,
  footer: PropTypes.node,
};

PreviewTabularData.defaultProps = {
  title: 'Tabular Data',
  subscriptionsReady: true,
  dataSource: [],
  onClose: () => {},
  footer: null,
};

export default PreviewTabularData;
