import { PlusOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import Button from '../../../components/Button';
import Stack from '../../../common/components/primitives/Stack';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Empty from '../../../common/components/base/Empty';
import ProjectProfileContainer from './ProjectProfile/ProjectProfileContainer';
import {
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_ATTACH_PARTICIPATION,
  PROJECT_PROFILE_SUBSCRIBE,
} from '../../../common/permissions';
import usePermission from '../../../utils/usePermission';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import {
  apiZedocSubscribeRecipientAllProjects,
  apiZedocUnsubscribeRecipientAllProjects,
} from '../../../common/api/zedoc';

const PatientProjects = ({ recipient, participations, onAddToProject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canAddParticipant = usePermission([PROJECT_ATTACH_PARTICIPATION]);

  const canSubscribe = usePermission([PROJECT_PROFILE_SUBSCRIBE], {
    relativeTo: recipient.getDomains(),
  });

  const canEnrollPatient = usePermission([PATIENT_ATTACH_PARTICIPATION], {
    relativeTo: recipient.getDomains(),
  });

  return (
    <Stack>
      <Sidebar
        sidebar={
          // one row of buttons with space around
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              data-testid="button-add-to-project"
              type="primary"
              icon={<PlusOutlined />}
              onClick={onAddToProject}
              disabled={!(canAddParticipant && canEnrollPatient)}
            >
              {t('addToProject')}
            </Button>
            {canSubscribe && (
              <span style={{ padding: '0px 10px' }}>
                <Button
                  data-testid="button-subsciption"
                  type="primary"
                  onClick={() => {
                    dispatch(
                      callMethod(apiZedocSubscribeRecipientAllProjects, {
                        recipientId: recipient._id,
                      }),
                    );
                  }}
                >
                  {t('subscribe_all')}
                </Button>
              </span>
            )}
            {canSubscribe && (
              <Button
                data-testid="button-unsubsciption"
                type="primary"
                onClick={() => {
                  dispatch(
                    callMethod(apiZedocUnsubscribeRecipientAllProjects, {
                      recipientId: recipient._id,
                    }),
                  );
                }}
              >
                {t('unsubscribe_all')}
              </Button>
            )}
          </div>
        }
      />
      {isEmpty(participations) ? (
        <Empty />
      ) : (
        map(participations, (participation) => (
          <ProjectProfileContainer
            key={participation._id}
            participation={participation}
          />
        ))
      )}
    </Stack>
  );
};

PatientProjects.propTypes = {
  recipient: PropTypes.instanceOf(Recipient).isRequired,
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  onAddToProject: PropTypes.func.isRequired,
};

PatientProjects.defaultProps = {
  participations: [],
};

export default PatientProjects;
